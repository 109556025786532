<template>
    <zw-sidebar @shown="shown" :title="$t('common.title.json')">
        <b-button @click="toggleExpand">
            <font-awesome-icon v-if="isExpanded" icon="folder"/>
            <font-awesome-icon v-if="!isExpanded" icon="folder-open"/>
        </b-button>
        <json-viewer v-if="payload.data"
                     :value="payload.data"
                     :key="jsonKey"
                     :expand-depth="expandDepth"
        ></json-viewer>
    </zw-sidebar>
</template>

<script>
export default {
    name: 'JsonModal',
    data() {
        return {
            payload: {},
            isExpanded: false,
            expandDepth: 1,
            jsonKey: 0
        }
    },
    methods: {
        shown() {
        },
        toggleExpand() {
            this.isExpanded = !this.isExpanded;
            this.expandDepth = this.isExpanded ? Infinity : 1;
            this.jsonKey++;
        }
    },
}
</script>